import React from 'react'

import { Link } from 'gatsby'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import bannerImg from '../../../../images/integrations/optiwe_gvamax.jpg'
import Layout from '../../../../components/layout'
import Nav from '../../../../components/nav'
import SEO from '../../../../components/seo'
import '../../../../styles/index.scss'

const WebinarsPage = () => {
    return (
        <Layout lang="es">

            <SEO
                title="Webinar Abril 2023 - GVAMax y Optiwe"
                description="Webinar sobre como mejorar las ventas y la atención al cliente para
                    clientes de GVAMax"
                lang="es"
                locales={[{
                    hreflang: 'es',
                    href: 'https://optiwe.com/es/recursos/webinars/abril-2023-con-gvamax/'
                }]}
            />

            <main className="container-fluid ow-banner">
                {
                    /*
                    ^*********
                    * HEADER *
                    **********
                    */
                }
                <Nav lang="es" showButtons />

                {
                    /*
                    ***************
                    * MAIN BANNER *
                    ***************
                    * TODO: If the banner is used in another view, create a <Banner /> component
                    */
                }
                <div className="container ow-banner__content">
                    <div className="row">
                        <div className="col-xl-6 col-xxl-6 ow-banner__home-content">
                            <h1 className="ow-banner__title">
                                <strong>Webinar |</strong> Optiwe en colaboración con GVAMax.
                            </h1>
                            <p className="ow-banner__text">
                                Toda la información sobre cómo automatizar la atención al cliente
                                en WhatsApp con Inteligencia Artificial.
                            </p>
                            <p className="ow-banner__text">
                                Cuando: Viernes 28 de Abril 2023 a las 12 pm hora de Argentina.
                            </p>
                            <p className="ow-banner__text">
                                Para anotarte al webinar, utiliza {' '}
                                <a href="https://us06web.zoom.us/meeting/register/tZcsf-CvrzooGNPdqfhgXZBkVwYUWNHonAEf"
                                    target="_blank" rel="noreferrer"
                                >
                                    el siguiente formulario de Zoom.
                                </a>
                            </p>
                            <p className="ow-banner__text">
                                1. Cómo atender de formar centralizada los potenciales y actuales
                                clientes en canales como WhatsApp, Facebook e Instagram.
                            </p>
                            <p className="ow-banner__text">
                                2. Como centralizar la información de los clientes que te llegan
                                por las redes sociales en GVAMax.
                            </p>
                            <p className="ow-banner__text">
                                3. Como crear chatbots para vender mas y automatizar la atención
                                al cliente.
                            </p>
                            <p className="ow-banner__text">
                                4. Cómo realizar envíos masivos por WhatsApp para aumentar las
                                ventas.
                            </p>
                            <p className="ow-banner__text">
                                Mas beneficios de la integración en <Link
                                    to="/es/integraciones/gvamax/"
                                >
                                    el siguiente enlace.
                                </Link>
                            </p>
                        </div>
                        <div className="col-md-6 col-xxl-7 ow-banner__home-image">
                            <img src={bannerImg} alt="optiwe" className="ow-banner__img" />
                        </div>
                    </div>
                </div>
            </main>

        </Layout>
    )
}

export default WebinarsPage
